var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"dropdown",staticClass:"ds-controlled-dropdown-component",style:({ height: _vm.height })},[_c('div',{ref:"clickArea",class:['trigger', _setup.customId, { fitContent: _vm.fitContent }],on:{"click":() => _setup.handleMouseEvent('click'),"mouseenter":() => _setup.handleMouseEvent('mouseenter'),"mouseleave":() => _setup.handleMouseEvent('mouseleave')}},[_vm._t("trigger-zone")],2),_c(_setup.Teleport,{attrs:{"to":"#ds-dropdowns"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],ref:"dropdownArea",class:['ControlledDropdown', _setup.customId, _vm.direction, { nostyle: _vm.nostyle }],style:({
                '--dropdown-position-y': `${_setup.positionTop}px`,
                '--dropdown-position-x': `${_setup.positionLeft}px`,
                'min-width': `${_setup.minWidth}px`,
                'max-width': _vm.maxWidth,
                width: `${_vm.width}px`,
            }),attrs:{"id":_setup.customId},on:{"mouseenter":() => _setup.handleMouseEvent('mouseenter'),"mouseleave":() => _setup.handleMouseEvent('mouseleave')}},[_c('div',{staticClass:"dropdown-content",style:({ maxHeight: _vm.maxHeight, ..._vm.styles })},[_vm._t("dropdown-content")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }