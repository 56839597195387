import { isValidEmail, isValidPhone } from "@/helpers/commons";
import i18n from "@/i18n";

interface ValidationResult {
    error: string | undefined;
    isValid: boolean;
}

export const validate = (value: string | number, type: string, required = false, valid = false): ValidationResult => {
    let error = undefined;
    if (value && typeof value === "string") {
        switch (type) {
            case "email": {
                if (!isValidEmail(value)) {
                    error = "Entrez une adresse e-mail valide";
                    return { error, isValid: false };
                }
                break;
            }
            case "tel": {
                if (!isValidPhone(value)) {
                    error = "Entrez un numero de téléphone valide";
                    return { error, isValid: false };
                }
                break;
            }
        }
    }
    const isRequiredAndError = required && value === "";
    error = isRequiredAndError ? i18n.t("errors.Validation.Any.Base._default").toString() : undefined;
    const isValid = value !== "" && valid !== false;
    return { error, isValid };
};

export const validateEvoliaId = (value: string): ValidationResult => {
    if (value.length < 6) {
        return { error: "Le numéro de fiche Evolia doit contenir 6 chiffres", isValid: false };
    }
    return { error: undefined, isValid: true };
};
